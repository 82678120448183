import { Fetch } from './FetchApi';
import Config from '../Config';

import { QuestionFormValues } from '../components/QuestionForm';

const BASE_URL = Config.BACKEND_URL;
const EVENT_CODE = Config.CLIENT;
const DEPLOYMENT = deploymentFromConfigUrl(Config);
const EVENT_SPACE_PREFIX = 'aws_';
const EMAIL_API = `${BASE_URL}/event/${EVENT_SPACE_PREFIX}${EVENT_CODE}/email`;

function deploymentFromConfigUrl(config: any) {
  const match = config.URL_CONFIG.match(/^.*?\/(\w+)\/frontend.json$/);
  if (!match) {
    console.error(`Unable to determine deployment from '${config.URL_CONFIG}'`);
  }
  return match[1];
}

async function sendQuestion(formValues: QuestionFormValues) {
  return await Fetch(EMAIL_API, {
    method: 'POST',
    body: {
      deployment: DEPLOYMENT,
      emailType: 'askQuestion',
      fields: { ...formValues },
    },
  });
}

export default sendQuestion;

import React from 'react';

import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    minHeight: '800px',
  },
  alert: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default (props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Alert
          variant="outlined"
          severity={props.message.type}
          className={classes.alert}
        >
          <span
            dangerouslySetInnerHTML={{ __html: props.message.description }}
          ></span>
        </Alert>
      </Container>
    </div>
  );
};

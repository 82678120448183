import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import moment from 'moment-timezone';
import { makeStyles } from 'tss-react/mui';
import Parser from 'html-react-parser';
import Footer from '../components/layout/Footer';
import SurveyLink from '../components/SurveyLink';
import './index.css';

let timer = null;

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: '#001c4b',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    padding: '2% 5%',
    justifyContent: 'space-between',
  },
  body: {
    //backgroundColor: 'rgba(0, 0, 0, 0.3)',
    paddingBottom: '0px', //theme.spacing(28),
    flex: 'auto',
    '& *': {
      color: 'white !important',
    },
  },
  hero: {
    paddingBottom: theme.spacing(3),
    maxWidth: '100%',
    margin: '0 auto',
    textAlign: 'center',
    animation: 'slideInUp .5s linear',
    transition: '.5s ease-in-out',
  },
  herotitle: {
    fontFamily: '"Ember", sans-serif',
    fontSize: '40px',
    lineHeight: '2.5rem',
    fontWeight: '700',
  },
  subtitle: {
    fontFamily: '"Ember", sans-serif',
    fontWeight: '500',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: '21px',
    lineHeight: '1.3',
    [theme.breakpoints.up('md')]: {
      fontSize: '21px',
      lineHeight: '1.3',
    },
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: '400px',
  },
  grid: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    // paddingLeft: '58px',
    // paddingRight: '58px'
  },
  button: {
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    padding: '11px 33px',
  },
}));

export default function Home(props) {
  const navigate = useNavigate();
  const footer = props.config.footer;
  const [isInitialised, setIsInitialised] = useState(false);
  useEffect(() => {
    setIsInitialised(true);
  }, [navigate]);
  const { classes } = useStyles();
  const buttons = props.config.brand.color.buttons;
  const bodyBackGroundColor = props.config.brand.color.backgroundColor;
  const textColor = props.config.brand.color.textColor;

  const DEFAULT_LOBBY_DURATION = 5;
  const KEYNOTE_LOBBY_DURATION = 15;
  const POST_SHOW_LIVE_DURATION = 20;

  const preEventTitles = props.config.homePage.preEventTitle.split('/n ');
  const preEventTitle = [];
  for (const title of preEventTitles) {
    if (title.indexOf("<a href='") > -1) {
      let preStr = title.toString().substr(0, title.toString().indexOf('<a'));
      let linkTitle = title
        .toString()
        .slice(
          title.toString().indexOf("'>") + 2,
          title.toString().indexOf('</a>')
        );
      let link = title
        .toString()
        .slice(
          title.toString().indexOf("<a href='") + 9,
          title.toString().indexOf("'>")
        );
      let postStr = title
        .toString()
        .slice(title.toString().indexOf('</a>') + 4, title.toString().length);
      preEventTitle.push(
        <>
          {preStr}
          {
            <a href={link} style={{ color: `${textColor.homeCtaText}` }}>
              {linkTitle}
            </a>
          }
          {postStr}
          <br />
        </>
      );
    } else {
      preEventTitle.push(
        <>
          {title}
          <br />
        </>
      );
    }
  }

  const postEventTitles = props.config.homePage.postEventTitle.split('/n ');
  const postEventTitle = [];
  for (const title of postEventTitles) {
    if (title.indexOf("<a href='") > -1) {
      let preStr = title.toString().substr(0, title.toString().indexOf('<a'));
      let linkTitle = title
        .toString()
        .slice(
          title.toString().indexOf("'>") + 2,
          title.toString().indexOf('</a>')
        );
      let link = title
        .toString()
        .slice(
          title.toString().indexOf("<a href='") + 9,
          title.toString().indexOf("'>")
        );
      let postStr = title
        .toString()
        .slice(title.toString().indexOf('</a>') + 4, title.toString().length);
      postEventTitle.push(
        <>
          {preStr}
          {
            <a href={link} style={{ color: `${textColor.homeCtaText}` }}>
              {linkTitle}
            </a>
          }
          {postStr}
          <br />
        </>
      );
    } else {
      postEventTitle.push(
        <>
          {title}
          <br />
        </>
      );
    }
  }

  const preEventSubtitles = props.config.homePage.preEventSubtitle.split('/n ');
  const preEventSubtitle = [];
  for (const subtitle of preEventSubtitles) {
    if (subtitle.indexOf("<a href='") > -1) {
      let preStr = subtitle
        .toString()
        .substr(0, subtitle.toString().indexOf('<a'));
      let linkTitle = subtitle
        .toString()
        .slice(
          subtitle.toString().indexOf("'>") + 2,
          subtitle.toString().indexOf('</a>')
        );
      let link = subtitle
        .toString()
        .slice(
          subtitle.toString().indexOf("<a href='") + 9,
          subtitle.toString().indexOf("'>")
        );
      let postStr = subtitle
        .toString()
        .slice(
          subtitle.toString().indexOf('</a>') + 4,
          subtitle.toString().length
        );
      preEventSubtitle.push(
        <>
          {preStr}
          {
            <a href={link} style={{ color: `${textColor.homeCtaText}` }}>
              {linkTitle}
            </a>
          }
          {postStr}
          <br />
        </>
      );
    } else {
      preEventSubtitle.push(
        <>
          {subtitle}
          <br />
        </>
      );
    }
  }

  const postEventSubtitles =
    props.config.homePage.postEventSubtitle.split('/n ');
  const postEventSubtitle = [];
  for (const subtitle of postEventSubtitles) {
    if (subtitle.indexOf("<a href='") > -1) {
      let preStr = subtitle
        .toString()
        .substr(0, subtitle.toString().indexOf('<a'));
      let linkTitle = subtitle
        .toString()
        .slice(
          subtitle.toString().indexOf("'>") + 2,
          subtitle.toString().indexOf('</a>')
        );
      let link = subtitle
        .toString()
        .slice(
          subtitle.toString().indexOf("<a href='") + 9,
          subtitle.toString().indexOf("'>")
        );
      let postStr = subtitle
        .toString()
        .slice(
          subtitle.toString().indexOf('</a>') + 4,
          subtitle.toString().length
        );
      postEventSubtitle.push(
        <>
          {preStr}
          {
            <a href={link} style={{ color: `${textColor.homeCtaText}` }}>
              {linkTitle}
            </a>
          }
          {postStr}
          <br />
        </>
      );
    } else {
      postEventSubtitle.push(
        <>
          {subtitle}
          <br />
        </>
      );
    }
  }

  const headerLogoLeft = (
    <div className="logo-wrapper">
      <picture className="picture">
        <img src={`${props.config.brand.image.logo}`} alt="" />
      </picture>
      <SurveyLink />
    </div>
  );

  const renderEventStartComponent = (
    <>
      {headerLogoLeft}
      <div className="hero">
        <div
          className="herotitle"
          style={{ color: `${textColor.homeCtaText}` }}
        >
          {Parser(props.config.homePage.preEventTitle)}
        </div>
        <br />
        <div className="subtitle" style={{ color: `${textColor.homeCtaText}` }}>
          {Parser(props.config.homePage.preEventSubtitle)}
        </div>
      </div>
    </>
  );

  const renderEventEndComponent = (
    <>
      {headerLogoLeft}
      <div className="hero">
        <div
          className="herotitle"
          style={{ color: `${textColor.homeCtaText}` }}
        >
          {Parser(props.config.homePage.postEventTitle)}
        </div>
        <br />
        <div className="subtitle" style={{ color: `${textColor.homeCtaText}` }}>
          {Parser(props.config.homePage.postEventSubtitle)}
        </div>
      </div>
    </>
  );

  let eventDate = props.config.homePage.eventdate;
  const eventDateDay = eventDate.split(' ')[0];
  const eventDateMonth = eventDate.split(' ')[1];
  const eventDateYear = eventDate.split(' ')[2];
  if (eventDateDay && eventDateMonth && eventDateYear) {
    const eventTime = props.config.homePage.eventtime;
    let startTime = null;
    let endTime = null;
    let startDateTime = null;
    let endDateTime = null;
    if (eventTime && eventTime.split('-').length === 2) {
      startTime = eventTime.split('-')[0].trim();
      endTime = eventTime.split('-')[1].trim();
    }
    if (startTime && endTime) {
      if (props.config.timezone) {
        startDateTime = moment.tz(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${startTime}`,
          'D-MMMM-YYYY hh:mm A',
          props.config.timezone.value
        );
        endDateTime = moment.tz(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${endTime}`,
          'D-MMMM-YYYY hh:mm A',
          props.config.timezone.value
        );
      } else {
        startDateTime = Moment(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${startTime}`,
          'Do-MMMM-YYYY HH:mm'
        );
        endDateTime = Moment(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${endTime}`,
          'Do-MMMM-YYYY HH:mm'
        );
      }
    } else {
      startDateTime = Moment(
        `${eventDateDay}-${eventDateMonth}-${eventDateYear}` + ' 00:00',
        'Do-MMMM-YYYY HH:mm'
      );
      endDateTime = Moment(
        `${eventDateDay}-${eventDateMonth}-${eventDateYear}` + ' 23:59',
        'Do-MMMM-YYYY HH:mm'
      );
    }
    if (startDateTime && endDateTime) {
      const isKeynote = true;
      const goLiveTime = isKeynote
        ? startDateTime.subtract(KEYNOTE_LOBBY_DURATION, 'minutes')
        : startDateTime.subtract(DEFAULT_LOBBY_DURATION, 'minutes');
      const endLiveTime = endDateTime.add(POST_SHOW_LIVE_DURATION, 'minutes');

      const hasBegun = Moment().isAfter(goLiveTime);
      const hasEnded = Moment().isAfter(endLiveTime);

      if (!hasBegun) {
        if (
          isInitialised &&
          props.currentContent !== renderEventStartComponent
        ) {
          timer = setTimeout(() => {
            props.setCurrentContent(renderEventStartComponent);
            clearTimeout(timer);
          }, 500);
        }
      } else if (hasEnded) {
        console.log('Setting to render event end');
        if (isInitialised && props.currentContent !== renderEventEndComponent) {
          timer = setTimeout(() => {
            props.setCurrentContent(renderEventEndComponent);
            clearTimeout(timer);
          }, 1000);
        }
      } else {
        console.log('Setting to render event video');
        if (isInitialised) {
          navigate('/live');
        }
      }
    }
  }

  if (!isInitialised) {
    return null;
  }

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url("${props.config.brand.image.landingPoster}"`,
      }}
    >
      <div className={classes.body}>{props.currentContent}</div>
      <Footer footer={footer} />
      {/* <Footer Logo={<img src={`${props.config.brand.image.logo}`} style={{width: '150px'}} alt=""/>} footer={footer} /> */}
    </div>
  );
}

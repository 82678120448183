import { Fetch } from './FetchApi';
import Config from '../Config';

export const FetchConfig = async (url) => {
  const payload = {
    method: 'GET',
  };
  return await Fetch(url, payload);
};

export const GetUrl = () => {
  return Config.URL_CONFIG + '?v=' + new Date().getTime();
};

export const GetDefaultVideo = (config) => {
  for (let i = 0; i < config.videos.length; i++) {
    if (config.defaultVideo === config.videos[i].id) {
      return config.videos[i];
    }
  }
};

export const GetVodVideo = (config) => {
  return config.videos.find((x) => x.id.toLowerCase() === 'vod');
};

export const UpdateConfig = (config) => {
  return config;
};

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Home from './containers/Home';
import LiveStream from './containers/LiveStream';
import ASLStream from './containers/ASLStream';
import Vod from './containers/Vod';

export default function EventRoutes({ appProps, config }) {
  return (
    <Routes>
      <Route path="/" exact element={<Home {...appProps} />} />
      <Route path="/index.html" element={<Navigate replace to={`/`} />} />
      <Route path="/live" exact element={<LiveStream {...appProps} />} />
      <Route path="/vod" exact element={<Vod {...appProps} />} />
      <Route
        path="/asl-livestream"
        exact
        element={<ASLStream {...appProps} />}
      />
      <Route element={<Navigate replace to={`/`} />} />
    </Routes>
  );
}

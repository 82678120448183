import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import sendQuestion from '../helpers/sendQuestion';

interface QuestionFormValues {
  name: string;
  question: string;
}

const useStyles = makeStyles()((theme) => ({
  button: {
    color: 'white',
    border: '1px solid white',
    borderRadius: 0,
    fontSize: '1.1rem',
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    padding: '8px 14px',
    textTransform: 'none',
  },
  container: {
    paddingTop: theme.spacing(3),
  },
  control: {
    display: 'flex-item',
    justifyContent: 'left',
  },
  field: {
    background: 'white',
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    // Unscoped `label` is selected in CL's `index.css` and `color` applied with `important`
    color: 'black !important',
  },
  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  instructions: {
    '& p': {
      fontWeight: 500,
      fontSize: '1.3rem',
      textAlign: 'left',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  item: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  message: {
    textAlign: 'left',
  },
}));

function QuestionForm() {
  const { classes } = useStyles();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, touchedFields, isSubmitting, isSubmitted },
  } = useForm<QuestionFormValues>();
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const message = submissionError
    ? 'We encountered an error while submitting your question'
    : 'Thank you, your question has been submitted';
  const hasMessage = isSubmitted && Object.keys(touchedFields).length;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await sendQuestion(data);
      setValue('name', '');
      setValue('question', '');
      setSubmissionError(null);
    } catch (error) {
      setSubmissionError('Submission Error');
      return false;
    }
  });

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.item} item md={6}>
        <div className={classes.instructions}>
          <p>Have a question?</p>
          <p>Submit here to ask our presenter. </p>
        </div>
      </Grid>

      <Grid className={classes.item} item md={6}>
        <Box className={classes.form} component="form" onSubmit={onSubmit}>
          <TextField
            className={classes.field}
            error={Boolean(errors.name)}
            fullWidth
            helperText={errors.name?.message}
            InputLabelProps={{ className: classes.inputLabel }}
            label="Name"
            variant="filled"
            {...register('name', {
              required: 'Please enter your name',
            })}
          />

          <TextField
            className={classes.field}
            error={Boolean(errors.question)}
            fullWidth
            helperText={errors.question?.message}
            InputLabelProps={{ className: classes.inputLabel }}
            label="Question"
            multiline
            variant="filled"
            minRows={4}
            maxRows={10}
            {...register('question', {
              required: 'Please enter your question',
            })}
          />

          <div className={classes.control}>
            <Button
              disabled={isSubmitting}
              className={classes.button}
              type="submit"
            >
              Submit
            </Button>
          </div>

          {hasMessage && <div className={classes.message}>{message}</div>}
        </Box>
      </Grid>
    </Grid>
  );
}

export type { QuestionFormValues };

export default QuestionForm;

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { FaTwitter, FaFacebookF, FaTwitch } from 'react-icons/fa';
import { TfiYoutube } from 'react-icons/tfi';

const AMAZON_ORANGE = '#f8991d';

const useStyles = makeStyles()({
  root: {
    '& a': {
      color: 'white',
      textDecoration: 'none',
    },
  },
  socials: {
    '& svg': {
      color: 'white',
      fontSize: 25,
      marginRight: 10,
    },
    '& svg:hover': {
      color: AMAZON_ORANGE,
    },
  },
});

function Footer({ footer }) {
  const { classes } = useStyles();

  return (
    <footer className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item className={classes.socials}>
          <a
            href="https://twitter.com/AWSCloudSEAsia"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.facebook.com/amazonwebservices"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
          <a href="https://www.twitch.tv/aws" target="_blank" rel="noreferrer">
            <FaTwitch />
          </a>
          <a
            href="https://www.youtube.com/user/AmazonWebServices/Cloud/"
            target="_blank"
            rel="noreferrer"
          >
            <TfiYoutube />
          </a>
        </Grid>

        <Grid item>
          <a href={footer.policyLink} target="_blank" rel="noopener noreferrer">
            {footer.policy}
          </a>{' '}
          •&nbsp;
          <a href={footer.rightsLink} target="_blank" rel="noopener noreferrer">
            {footer.rights}
          </a>
          &nbsp;
          {footer.copyright1}
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;

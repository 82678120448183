import React from 'react';
import initTHEOplayerMux from '@mux/mux-data-theoplayer';

import 'theoplayer/ui.css';
import './TheoPlayer.css';
import Config from '../../../Config';

class VideoPlayer extends React.Component {
  _player = null;
  _el = React.createRef();

  componentDidMount() {
    const { onPlay, videoId, videoTitle, videoUrl } = this.props;

    // Theo SourceDescription interface
    const source = {
      sources: [videoUrl],
    };

    if (this._el.current) {
      this._player = new window.THEOplayer.Player(this._el.current, {
        libraryLocation: `${process.env.PUBLIC_URL}/vendor/theoplayer`,
        license: Config.THEO_LICENSE_KEY,
      });

      this._player.source = source;
      this._player.muted = true;
      this._player.autoplay = true;
      this._player.addEventListener('play', onPlay);

      initTHEOplayerMux(this._player, {
        debug: true,
        data: {
          env_key: Config.MUX_ANALYTICS_KEY,
          sub_property_id: Config.CLIENT,
          player_name: 'main',
          player_init_time: Date.now(),
          video_id: videoId,
          video_title: videoTitle,
        },
      });
    }
  }

  componentDidUpdate({ videoId: previousVideoId }) {
    const { videoId, videoTitle, videoUrl } = this.props;
    const hasVideoSwitched = previousVideoId !== videoId;

    if (hasVideoSwitched) {
      const source = {
        sources: [videoUrl],
      };

      this._player.source = source;
      this.setState({
        videoTitle,
        videoId,
      });
    }
  }

  componentWillUnmount() {
    if (this._player) {
      this._player.destroy();
    }
  }

  render() {
    return (
      <div
        className={'theoplayer-container video-js theoplayer-skin vjs-16-9'}
        ref={this._el}
      />
    );
  }
}

export default VideoPlayer;

export default {
  CLIENT: 'aseansummit2023',
  URL_CONFIG: "https://d1o4t9h6a8fm0w.cloudfront.net/cms/events/aws/aseansummit2023/prod/frontend.json",
  BITMOVIN_ANALYTICS_KEY: "E404F786-B72B-4912-B7D8-D559FE702C7F",
  MUX_ANALYTICS_KEY: "g390j6uqqi612ienbuk94q10h",
  COGNITO_REGION: "ap-southeast-2",
  COGNITO_USER_POOL_ID: "null",
  COGNITO_APP_CLIENT_ID: "null",
  COGNITO_IDENTITY_POOL_ID: "null",
  DOMAIN_WHITELIST: '[]',
  BACKEND_API_NAME: 'backend-api',
  BACKEND_REGION: 'ap-southeast-2',
  BACKEND_URL: "https://gk4rfxej15.execute-api.ap-southeast-2.amazonaws.com/prod",
  BACKEND_FUNCTION_PREFIX: "email-prod-prod-aws-aseansummit2023",
  ZENDESK_API_TOKEN: "42pzgqiuUL6qTOtljOIJWcELj2lJN3qA9ADBL9Ig",
  EVENT_NAME: 'AWS Event',
  THEO_LICENSE_KEY: 'sZP7IYe6T6Pg3uBLISaZI6k1CoaoFSac3Ka-CKIeCZk6IDhrIl0o3oCz3lh6FOPlUY3zWokgbgjNIOf9flhk0LPl3uBcFSRLTSh-3uR_3Ok1CohrFDIK0LAK0Sec0uP63OfVfK4_bQgZCYxNWoryIQXzImf90SC_0L5Z0SCi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lRZ3uecTShZ3Lh_FOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya',
  FEATURE_FLAGS: ['question-form']
};

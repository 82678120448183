import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import VideoPlayer from '../components/video/VideoPlayer';
import './index.css';

const useStyles = makeStyles()((theme) => ({
  background: {
    backgroundColor: '#e3e9ec',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'start',
  },
  background2: {
    //backgroundColor: 'rgba(0, 0, 0, 0.3)',
    paddingTop: '40px', //theme.spacing(28),
    paddingBottom: '0px', //theme.spacing(28),
  },
  hero: {
    paddingBottom: theme.spacing(3),
    maxWidth: '700px',
    margin: '0 auto',
    textAlign: 'center',
    animation: 'slideInUp .5s linear',
    transition: '.5s ease-in-out',
  },
  herotitle: {
    fontFamily: '"Ember", sans-serif',
    fontSize: '40px',
    lineHeight: '2.5rem',
    fontWeight: '700',
  },
  subtitle: {
    fontFamily: '"Ember", sans-serif',
    fontWeight: '500',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: '21px',
    lineHeight: '1.3',
    [theme.breakpoints.up('md')]: {
      fontSize: '21px',
      lineHeight: '1.3',
    },
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: '400px',
  },
  grid: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    // paddingLeft: '58px',
    // paddingRight: '58px'
  },
  button: {
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    padding: '11px 33px',
  },
}));

export default (props) => {
  const navigate = useNavigate();
  const footer = props.config.footer;
  const defaultVideo = useMemo(() => props.config.aslStream, [props.config]);
  const [isInitialised, setIsInitialised] = useState(false);
  useEffect(() => {
    setIsInitialised(true);
  }, [navigate]);
  const { classes } = useStyles();
  const textColor = props.config.brand.color.textColor;

  const viewLiveStream = async () => {
    navigate('/live');
  };

  const headerLogoRight = (
    <>
      <br />
      <br />
      <br />
    </>
  );

  const watchLiveStreamButton = (
    <>
      <button
        className="button-transparent-border float-right"
        onClick={viewLiveStream}
      >
        <strong>Watch Livestream</strong>
      </button>
    </>
  );

  const footerWithoutLogo = (
    <footer className="footer-container">
      <p>
        <a href={footer.policyLink} target="_blank" rel="noopener noreferrer">
          {footer.policy}
        </a>{' '}
        •&nbsp;
        <a href={footer.rightsLink} target="_blank" rel="noopener noreferrer">
          {footer.rights}
        </a>{' '}
        •&nbsp;
        {footer.copyright1}
      </p>
    </footer>
  );

  const footerWithLogo = (
    <footer className="footer-container">
      <p>
        <span>
          <img
            src={`${props.config.brand.image.logo}`}
            style={{ width: '150px' }}
            alt=""
          />
        </span>
        <span>
          <a href={footer.policyLink} target="_blank" rel="noopener noreferrer">
            {footer.policy}
          </a>{' '}
          •&nbsp;
          <a href={footer.rightsLink} target="_blank" rel="noopener noreferrer">
            {footer.rights}
          </a>{' '}
          •&nbsp;
          {footer.copyright1}
        </span>
      </p>
    </footer>
  );

  const renderEventVideoPlayer = (
    <>
      {headerLogoRight}
      <div className="video-container">
        <VideoPlayer
          videoUrl={defaultVideo.videoUrl}
          videoId={defaultVideo.videoId}
          videoTitle={defaultVideo.videoTitle}
        />
      </div>
      <br />
      {watchLiveStreamButton}
      {footerWithoutLogo}
    </>
  );

  return (
    <>
      {isInitialised && props.config.aslStream && (
        <>
          <div
            className={classes.background}
            style={{
              backgroundImage: `url("${props.config.brand.image.landingPoster}"`,
              minHeight: '110vh',
            }}
          >
            <div className={classes.background2}>{renderEventVideoPlayer}</div>
          </div>
        </>
      )}
    </>
  );
};

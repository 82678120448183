import React, { useState, useEffect } from 'react';
import { FetchConfig, GetUrl, UpdateConfig } from './helpers/ConfigApi';
import { useInterval } from './hooks/useInterval';
import { makeStyles } from 'tss-react/mui';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './app.css';
import EventRoutes from './EventRoutes';
import { Helmet } from 'react-helmet';
import Loading from './containers/Loading';

const useStyles = makeStyles()((theme) => ({
  background: {
    backgroundColor: '#001c4b',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
  },
}));

const App = (props) => {
  const [isInitialising, setIsInitialising] = useState(true);
  const [currentContent, setCurrentContent] = useState();
  const [message, setMessage] = useState({
    type: 'info',
    description: 'Loading ... ',
  });
  const [config, setConfig] = useState();
  const [url, setUrl] = useState(GetUrl());
  const metaTag = config && config.metaTags;
  const faviconImg = config && config.brand.image.favicon;
  useEffect(() => {
    if (url) {
      FetchConfig(url)
        .then((newConfig) => {
          if (newConfig) {
            const updatedConfig = UpdateConfig(newConfig);
            setConfig(updatedConfig);
            setIsInitialising(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setMessage({ type: 'error', description: e.message });
        });
    }
  }, [url]);

  useInterval(() => {
    setUrl(GetUrl());
  }, 60000);

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    const previewImg = document.getElementById('previewImage');
    const ogTitle = document.getElementById('ogTitle');
    const ogDescription = document.getElementById('ogDescription');
    favicon.href = `https://d1o4t9h6a8fm0w.cloudfront.net/media/default/images/2023_Summits_Favicon_64x64.png`;
    previewImg.content = `${metaTag && metaTag.previewImage}`;
    ogTitle.content = `${metaTag && metaTag.title}`;
    ogDescription.content = `${metaTag && metaTag.description}`;
  });
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: '#042046',
        contrastText: '#fff',
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#042046',
      },
    },
    typography: {
      fontFamily: ['Ember', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ','
      ),
    },
    overrides: {
      MuiButton: {
        root: {
          minWidth: '100px',
        },
        outlinedPrimary: {
          border: '1px solid #042046',
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#f5f6f8',
          },
        },
      },
    },
  });

  let backgroundStyle = {};
  const brand = config && config.brand;
  const { classes } = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{metaTag && metaTag.title}</title>
        <meta name="description" content={metaTag && metaTag.description} />
        <meta property="og:title" content={metaTag && metaTag.title} />
        <meta property="favicon" content={metaTag && metaTag.faviconImage} />
        <meta property="og:image" content={metaTag && metaTag.previewImage} />
      </Helmet>
      <CssBaseline />

      <div className="App">
        <div className={classes.background} style={backgroundStyle}>
          <div className={classes.container}>
            {!isInitialising && (
              <EventRoutes
                appProps={{ config, currentContent, setCurrentContent }}
              />
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;

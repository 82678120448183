import { makeStyles } from 'tss-react/mui';

const ASEAN_SURVEY_URL =
  'https://amazonmr.au1.qualtrics.com/jfe/form/SV_3sWawzOP5ZUqAwC';
const ASEAN_SESSIONS_URL =
  'https://asean-resources.awscloud.com/aws-summit-online-asean-2023';

const useStyles = makeStyles()((theme) => ({
  surveyLink: {
    borderBottom: '2px solid white',
    color: 'white',
    display: 'inline-block',
    fontSize: 18,
    fontWeight: 600,
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));

function SurveyLink() {
  const { classes } = useStyles();

  return (
    <div>
      <a
        href={ASEAN_SESSIONS_URL}
        className={classes.surveyLink}
        target="_blank"
      >
        Watch sessions
      </a>

      <a href={ASEAN_SURVEY_URL} className={classes.surveyLink} target="_blank">
        Keynote survey
      </a>
    </div>
  );
}

export default SurveyLink;

import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Moment from 'moment';
import moment from 'moment-timezone';

import Config from '../Config';
import VideoPlayer from '../components/video/VideoPlayer';
import Footer from '../components/layout/Footer';
import SurveyLink from '../components/SurveyLink';
import './index.css';
import { GetDefaultVideo } from '../helpers/ConfigApi';
import QuestionForm from '../components/QuestionForm';

const QUESTION_FORM_ENABLED = Config.FEATURE_FLAGS.includes('question-form');

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: '#e3e9ec',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    padding: '2% 5%',
    justifyContent: 'space-between',
  },
  videoContainer: {
    //backgroundColor: 'rgba(0, 0, 0, 0.3)',
    paddingBottom: '0px', //theme.spacing(28),
  },
  player: {
    border: '2px solid white',
  },
  hero: {
    paddingBottom: theme.spacing(3),
    maxWidth: '100%',
    margin: '0 auto',
    textAlign: 'center',
    animation: 'slideInUp .5s linear',
    transition: '.5s ease-in-out',
  },
  herotitle: {
    fontFamily: '"Ember", sans-serif',
    fontSize: '40px',
    lineHeight: '2.5rem',
    fontWeight: '700',
  },
  subtitle: {
    fontFamily: '"Ember", sans-serif',
    fontWeight: '500',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: '21px',
    lineHeight: '1.3',
    [theme.breakpoints.up('md')]: {
      fontSize: '21px',
      lineHeight: '1.3',
    },
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: '400px',
  },
  grid: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    // paddingLeft: '58px',
    // paddingRight: '58px'
  },
  button: {
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    padding: '11px 33px',
  },
}));

export default function LiveStream(props) {
  const navigate = useNavigate();
  const footer = props.config.footer;
  let defaultVideo = useMemo(
    () => GetDefaultVideo(props.config),
    [props.config]
  );
  const [isInitialised, setIsInitialised] = useState(false);
  useEffect(() => {
    setIsInitialised(true);
  }, [navigate]);
  const { classes } = useStyles();
  const textColor = props.config.brand.color.textColor;

  const DEFAULT_LOBBY_DURATION = 5;
  const KEYNOTE_LOBBY_DURATION = 15;
  const POST_SHOW_LIVE_DURATION = 20;

  const viewASLStream = async () => {
    navigate('/asl-livestream');
  };

  const headerLogoLeft = (
    <div>
      <div className="logo-wrapper">
        <picture className="picture">
          <img src={`${props.config.brand.image.logo}`} alt="" />
        </picture>
        <SurveyLink />
      </div>
    </div>
  );

  const watchASLLiveStreamButton = (
    <>
      <button
        className="button-transparent-border float-right"
        onClick={viewASLStream}
      >
        <strong>Watch ASL Livestream</strong>
      </button>
    </>
  );

  let eventDate = props.config.homePage.eventdate;
  const eventDateDay = eventDate.split(' ')[0];
  const eventDateMonth = eventDate.split(' ')[1];
  const eventDateYear = eventDate.split(' ')[2];
  if (eventDateDay && eventDateMonth && eventDateYear) {
    const eventTime = props.config.homePage.eventtime;
    let startTime = null;
    let endTime = null;
    let startDateTime = null;
    let endDateTime = null;
    if (eventTime && eventTime.split('-').length === 2) {
      startTime = eventTime.split('-')[0].trim();
      endTime = eventTime.split('-')[1].trim();
    }
    if (startTime && endTime) {
      if (props.config.timezone) {
        startDateTime = moment.tz(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${startTime}`,
          'D-MMMM-YYYY hh:mm A',
          props.config.timezone.value
        );
        endDateTime = moment.tz(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${endTime}`,
          'D-MMMM-YYYY hh:mm A',
          props.config.timezone.value
        );
      } else {
        startDateTime = Moment(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${startTime}`,
          'Do-MMMM-YYYY HH:mm'
        );
        endDateTime = Moment(
          `${eventDateDay}-${eventDateMonth}-${eventDateYear} ${endTime}`,
          'Do-MMMM-YYYY HH:mm'
        );
      }
    } else {
      startDateTime = Moment(
        `${eventDateDay}-${eventDateMonth}-${eventDateYear}` + ' 00:00',
        'Do-MMMM-YYYY HH:mm'
      );
      endDateTime = Moment(
        `${eventDateDay}-${eventDateMonth}-${eventDateYear}` + ' 23:59',
        'Do-MMMM-YYYY HH:mm'
      );
    }
    if (startDateTime && endDateTime) {
      const isKeynote = true;
      const goLiveTime = isKeynote
        ? startDateTime.subtract(KEYNOTE_LOBBY_DURATION, 'minutes')
        : startDateTime.subtract(DEFAULT_LOBBY_DURATION, 'minutes');
      const endLiveTime = endDateTime.add(POST_SHOW_LIVE_DURATION, 'minutes');

      const hasBegun = Moment().isAfter(goLiveTime);
      const hasEnded = Moment().isAfter(endLiveTime);

      const isNotLive = !hasBegun || hasEnded;

      if (isNotLive) {
        if (isInitialised) {
          navigate('/');
          window.location.reload();
        }
      }
    }
  }

  if (!isInitialised) {
    return null;
  }

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url("${props.config.brand.image.landingPoster}"`,
      }}
    >
      <div className={classes.videoContainer}>
        {headerLogoLeft}
        <div>
          <div className="video-container">
            <div className={classes.player}>
              <VideoPlayer
                videoUrl={defaultVideo.videoUrl}
                videoId={defaultVideo.videoId}
                videoTitle={defaultVideo.videoTitle}
              />
            </div>

            {QUESTION_FORM_ENABLED && <QuestionForm />}
          </div>
        </div>

        {props.config.aslStream && watchASLLiveStreamButton}
      </div>
      <Footer footer={footer} />
      {/* <Footer Logo={<img src={`${props.config.brand.image.logo}`} style={{width: '150px'}} alt=""/>} footer={footer} /> */}
    </div>
  );
}
